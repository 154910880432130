import Vue from "vue";
import App from "./App.vue";
import "./assets/style/font.css";

import router from "./router/index";

Vue.config.productionTip = false;
import "viewerjs/dist/viewer.css";
import axios from 'axios'
import Viewer from "v-viewer";
import VueAxios from 'vue-axios'
import AlertAlert from 'vue-alert-alert'
import { Carousel, CarouselItem } from 'element-ui';
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(AlertAlert)
Vue.use(VueAxios, axios)
Vue.use(Viewer);
// 这里是插件的默认设置
Viewer.setDefaults({
  zIndexInline: 9999,
  defaultOptions: {
    zIndex: 9999,
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: true,
    tooltip: false,
    movable: true,
    zoomable: true,
    rotatable: true,
    scalable: false,
    transition: true,
    fullscreen: false,
    keyboard: false,
  },
});

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
