import Vue from 'vue'
import Router from 'vue-router'
// import chinese from '../view/chinese'
// import english from '../view/english'

Vue.use(Router)

const routes = [
    {path:'/',name:"chinese",component:()=>import('../view/chinese.vue')},
    {path:'/english',name:"english",component:()=>import('../view/english.vue')},
]

const routers = new Router({
    routes
})

export default routers